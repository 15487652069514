<template>
    <div class="vb-static-view">

      <section class="section">
        <div class="section__static-hero">
          <div class="section__static-backdrop">
            <img data-image="per-aziende" src="assets/images/alpitour/statics/per-aziende-hero.jpg" alt="per le aziende hero" />
          </div>
        </div>
      </section>

      <b-container>
        <section class="section">
          <h1 class="vb-heading vb-heading--h1 mb-5">Domande frequenti</h1>
          <loader v-if="loading" />

          <div v-else v-for="(faq, index) in faqs" v-bind:key="index">
            <h3 class="text-center">{{faq.category}}</h3>
            <b-row>
              <b-col lg="9" class="mb-3" role="tablist" v-for="(item, faq_index) in faq.items"
                     v-bind:key="_uid+faq_index">

                <vb-toggle
                    :vb_options="{
                      id: 'accordion_'+ index + '_' + faq_index,
                      btn_text: item.title,
                    }"
                >
                  <p>{{ item.content }}</p>
                </vb-toggle>

              </b-col>
            </b-row>
          </div>

        </section>
      </b-container>

    </div>
</template>

<script>

  import {service_handler} from "@src/services";

  export default {
    name: 'page',
    metaInfo: {
      title: 'Domande frequenti',
      meta: [
        { vmid: 'metaDescription', name: 'Description', content: 'Come si prenota, gli strumenti di pagamento, la rimborsabilità, ecc.' },
        { vmid: 'dcDescription', name: 'DC.description', lang: 'it', content: 'Come si prenota, gli strumenti di pagamento, la rimborsabilità, ecc.' },
      ],
    },
    components: {

    },
    data() {
        return {
            loading: true,
            faqs: [],
      }
    },
    mounted() {
      this.loading = true
      this.get_faqs()
    },
    methods:{
      get_faqs(){
        service_handler("getFaqs", {})
            .then(
                (success) => {
                  this.log("[faq.vue] - success")
                  this.faqs = success.data.faqs
                  this.loading = false
                },
                (error) => {
                  this.log("[faq.vue] - error")
                  this.log(error.response)
                  this.loading = false
                }
            )
      },
    }
  }
</script>

<style lang="scss" scoped>

.vb-btn--toggle, .vb-btn--toggle:focus {
  background-color: rgba(255,255,255, .3);
}

</style>